<template>
    <div>
        <v-layout wrap  >
            <v-flex xs12 py-4t text-center pt-7 pt-lg-12>
              <span class="bold" style="border-bottom:5px solid #ff8533 ;font-size:30px;padding-left:20px;padding-right:20px"> Gallery</span>
            </v-flex>
            <v-flex xs12>
            <v-layout wrap pa-3 pa-lg-12>
                <v-flex md3 v-for="(item,i) in images" :key="i">
                    <v-img height="500px" :src="require('../assets/images/gallery/' +item.image)">
                    </v-img>
                </v-flex>
            </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
export default {
    data()
    {
        return{
            images:[
                // {
                //    image:"a0.jpg"
                // },
                {
                   image:"VA2.jpeg"
                },
                 
                 {
                   image:"g2.jpg"
                },
                {
                   image:"NP6.jpeg"
                },
                 
                 {
                   image:"g4.jpg"
                },
                 {
                   image:"newFlagHost.jpeg"
                },
                 {
                   image:"g6.jpg"
                },
                 {
                   image:"g7.jpg"
                },
                 {
                   image:"g8.jpg"
                },
                 {
                   image:"g9.jpg"
                },
                 {
                   image:"newStudentsGroupPic.jpeg"
                },
                 {
                   image:"classGroupPic.jpeg"
                },
                 {
                   image:"g13.jpg"
                },
                 {
                   image:"g14.jpg"
                },
                  {
                   image:"g15.jpg"
                },
                  {
                   image:"g16.jpg"
                },
                




                {
                   image:"ED1.jpeg"
                },
                 {
                   image:"ED2.jpeg"
                },
                 {
                   image:"ED3.jpeg"
                },
                 {
                   image:"NP4.jpeg"
                },
                 {
                   image:"NP5.jpeg"
                },
                {
                   image:"g3.jpg"
                },
                 {
                   image:"NP7.jpeg"
                },
                 {
                   image:"NP8.jpeg"
                },
                 {
                   image:"NP9.jpeg"
                },
                {
                   image:"NP10.jpeg"
                },
                


                {
                   image:"VA1.jpeg"
                },
                {
                   image:"g1.jpg"
                },
                 {
                   image:"VA3.jpeg"
                },
                 {
                   image:"VA4.jpeg"
                },
                {
                   image:"NP11.jpeg"
                },
            ]
        }
    }
}
</script>